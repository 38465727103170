import React from "react";
import { useNavigate } from "react-router";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
} from "reactstrap";

const UserAssignationsModal = ({ onClose, user }) => {
  const navigate = useNavigate();

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>User Assignments</ModalHeader>
      <ModalBody>
        <Table className="col-12 px-0 mb-0 border" stripped="true">
          <thead>
            <tr className="bg-lighter small">
              <th>Work Order</th>
              <th>Trip#</th>
              <th>Is Lead</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody className="small">
            {user.workOrderEmployees.length ? (
              user.workOrderEmployees
                .sort((x, y) => y.isLead - x.isLead)
                .filter((workOrderEmployee) => !workOrderEmployee?.disabledAt)
                .map((workOrderEmployee) => {
                  return (
                    <tr key={workOrderEmployee.id}>
                      <td
                        className="text-underline cursor-pointer text-primary"
                        onClick={() =>
                          navigate(
                            `/work-orders/${workOrderEmployee.workOrderTrip.workOrderId}/details`
                          )
                        }
                      >
                        {workOrderEmployee.workOrderTrip.workOrder.jobNo}
                      </td>
                      <td>{workOrderEmployee.workOrderTrip.tripNumber}</td>
                      <td>{workOrderEmployee.isLead ? "Yes" : "No"}</td>
                      <td>
                        {workOrderEmployee.workOrderTrip.workOrder.jobType}
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan={3} className="text-center">
                  The user has no assignments
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserAssignationsModal;
