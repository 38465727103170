import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Badge,
  CardFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import moment from "moment";

import UserModal from "../../components/admin/users/UserModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";

import { ACTIONS, useUsers } from "../../providers/usersProvider";

import { employeesApi } from "../../services/employeeServices";
import {
  faInfoCircle,
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrashAlt,
  faUndoAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import CustomCheckboxWithLabel from "../../components/CustomCheckboxWithLabel";

const columns = (onEdit, onDelete, onRestore, onLastActivity, authContext) => [
  {
    accessor: "firstName",
    Header: "First Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { firstName } = rowData.row.original;
      return firstName || "-";
    },
  },
  {
    accessor: "lastName",
    Header: "Last Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { lastName } = rowData.row.original;
      return lastName || "-";
    },
  },
  {
    accessor: "email",
    Header: "Email",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { email } = rowData.row.original;
      return email || "-";
    },
  },
  {
    accessor: "employeeId",
    Header: "Employee Id",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { employeeId } = rowData.row.original;
      return employeeId || "-";
    },
  },
  {
    accessor: "isActive",
    Header: "Active",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { isActive } = rowData.row.original;
      return (
        <Badge color={isActive ? "secondary" : "warning"}>
          {isActive ? "Active" : "Inactive"}
        </Badge>
      );
    },
  },
  {
    accessor: "role.name",
    Header: "Role",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { role } = rowData.row.original;
      return role.name;
    },
  },
  {
    accessor: "serviceLocations",
    Header: "Service Locations",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { serviceLocations } = rowData.row.original;
      return serviceLocations.length
        ? serviceLocations.map((sl) => sl.name).join(", ")
        : "-";
    },
  },
  {
    accessor: "id",
    Header: "",
    disableSortBy: true,
    width: 320,
    cellProps: {
      className: "text-right",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        {rowData.row.original.isActive ? (
          <>
            {authContext.currentUser.email.includes("belovedrobot") ? (
              <Button
                size="sm"
                color="info"
                className="rounded mr-2"
                onClick={() => onLastActivity(rowData.row.original)}
              >
                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                <span>Last Activity</span>
              </Button>
            ) : null}
            <Button
              size="sm"
              className="rounded"
              color="warning"
              onClick={() => onEdit(rowData.row.original)}
            >
              <FontAwesomeIcon icon={faPen} className="mr-1" />
              <span>Edit</span>
            </Button>{" "}
            <Button
              size="sm"
              className="rounded ml-2"
              color="danger"
              onClick={() => onDelete(rowData.row.original)}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-1" />
              <span>Inactive</span>
            </Button>
          </>
        ) : (
          <Button
            size="sm"
            className="rounded ml-2"
            color="secondary"
            onClick={() => onRestore(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faUndoAlt} className="mr-1" />
            <span>Restore</span>
          </Button>
        )}
      </div>
    ),
  },
];

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(true);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createUserModal, setCreateUserModal] = useState(false);

  const [editUserModal, setEditUserModal] = useState(false);

  const [showInactive, setShowInactive] = useState();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          usersContext.sortBy === sortBy?.id &&
          usersContext.direction === direction
        ) {
          return;
        }
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [usersContext.direction, usersContext.sortBy, setUsersContext]
  );

  const onEdit = (user) => {
    setEditUserModal(user.id);
  };

  const onDelete = (user) => {
    if (user.id === authContext.currentUser.id) {
      return setInformationModal({
        isOpen: true,
        title: "Change to Inactive User",
        body: "Can't mark yourself as inactive.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        employeesApi
          .updateEmployee({
            ...user,
            firstName: user.firstName.trim(),
            lastName: user.lastName.trim(),
            email: user.email.trim(),
            serviceLocationIds: user.serviceLocations.map((ssl) => ssl.id),
            isActive: false,
          })
          .finally(() => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Inactive User",
              body: "User successfully marked as Inactive",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          })
          .catch((error) => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Change to Inactive User",
              body:
                error?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Change to Inactive User",
      body: `<p class="text-center">Are you sure you want to mark this employee as inactive?</p>
        <span>${user.email} - ${user.roleId}</span>`,
      confirmColor: "danger",
    });
  };

  const onRestore = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        employeesApi
          .updateEmployee({
            ...user,
            serviceLocationIds: user.serviceLocations.map((ssl) => ssl.id),
            isActive: true,
          })
          .finally(() => {
            setConfirmationModal(initConfirmationModal);
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          })
          .catch((error) => {
            setConfirmationModal(initConfirmationModal);
            setInformationModal({
              isOpen: true,
              title: "Restore User",
              body:
                error?.response?.data[0].msg ||
                "There was an error with your request.",
            });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Restore User",
      body: `<p class="text-center">Do you confirm you want to restore this user?</p>
        <span>${user.email} - ${user.roleId}</span>`,
      confirmColor: "danger",
    });
  };

  const setUsersContextCb = useCallback(
    (data) => setUsersContext(data),
    [setUsersContext]
  );

  useEffect(() => {
    setLoading(true);
    employeesApi
      .getEmployees({
        search: usersContext.search,
        page: usersContext.page - 1,
        pageSize: usersContext.sizePerPage,
        sortBy: usersContext.sortBy,
        direction: usersContext.direction,
        serviceLocationId: authContext.serviceLocationId,
        isActive: showInactive ? false : true,
      })
      .then((users) => {
        setLoading(false);
        setUsersContextCb({
          action: ACTIONS.GET_USERS_SUCCESS,
          payload: { users },
        });
      })
      .catch(() => {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      });
  }, [
    setUsersContextCb,
    usersContext.sortBy,
    usersContext.direction,
    usersContext.sizePerPage,
    usersContext.page,
    usersContext.search,
    usersContext.refresh,
    authContext.serviceLocationId,
    showInactive,
  ]);

  const onLastActivity = (user) => {
    setInformationModal({
      isOpen: true,
      title: "User Last Activity",
      rawBody: true,
      body: (
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            Platform and version
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            {user.appVersion || "-"}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            Last complete DB sync
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            {user.lastDbSync
              ? moment(user.lastDbSync).format("MM-DD-YYYY HH:mm")
              : "-"}
          </ListGroupItem>
        </ListGroup>
      ),
      onClose: null,
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(
            onEdit,
            onDelete,
            onRestore,
            onLastActivity,
            authContext
          )}
          data={usersContext.users?.data || []}
          pageSize={usersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: usersContext.sortBy,
            direction: usersContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Users</h3>
                <small className="text-muted ml-2 pt-1">
                  ({usersContext.users.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3 border rounded px-2 py-1">
                  <CustomCheckboxWithLabel
                    label="Include Inactive"
                    checked={showInactive}
                    onClick={() => setShowInactive(!showInactive)}
                  />
                </InputGroup>
                <InputGroup size="m" className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={usersContext.search}
                    onChange={(evt) =>
                      setUsersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-secondary text-white border-left-0 border-secondary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button"
                  color="secondary"
                  onClick={() => setCreateUserModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="rounded-circle d-flex custom-rounded-button ml-3"
                  color="secondary"
                  onClick={() =>
                    setUsersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              {usersContext.users.data?.length ? (
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0 overflow-hidden",
                  }}
                />
              ) : (
                <div className="text-center">No results</div>
              )}
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={usersContext.users.count}
                pageCount={usersContext.users.totalPages}
                currentPage={usersContext.page - 1}
                onPageChange={(page) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={usersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createUserModal ? (
        <UserModal
          onClose={() => {
            setCreateUserModal(false);
          }}
          onSubmit={() => {
            setCreateUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create User",
              body: "User created successfully.",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : editUserModal ? (
        <UserModal
          userId={editUserModal}
          onClose={() => {
            setEditUserModal(false);
          }}
          onSubmit={() => {
            setEditUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update User",
              body: "User Updated Successfully.",
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() => setInformationModal({ isOpen: false })}
        />
      ) : null}
    </Container>
  );
};

export default Users;
