import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Container,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Button from "reactstrap/lib/Button";
import { reportsApi } from "../../../services/reportsServices";
import { useAuth } from "../../../providers/authProvider";
import Loader from "../../../components/Loader";
import InformationModal from "../../../components/InformationModal";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EditEmployeeTime from "./EditEmployeeTime";
import { utils } from "../../../utils/utils";
import AddOtherTimeModal from "../../../components/admin/AddOtherTimeModal";

const OTHER_TIME = "OTHER_TIME";

const EmployeeEntry = ({
  employeeId,
  employeeName,
  employeeData,
  workOrderLabels,
  doRefresh,
  rawOtherTimes,
}) => {
  const [authContext] = useAuth();

  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const otherTimeTypes = new Set();

  const items = employeeData.reduce((p, c) => {
    const key = c.workOrderId || c.type;
    if (c.type) {
      otherTimeTypes.add(c.type);
    }
    if (!p[key]) {
      p[key] = {
        regHours: c.regHours,
        otHours: c.otHours,
        employeeId: c.employeeId,
        startTime: c.startTime,
        endTime: c.endTime,
      };
    } else {
      p[key] = {
        regHours: p[key].regHours + c.regHours,
        otHours: p[key].otHours + c.otHours,
        employeeId: p[key].employeeId,
      };
    }
    return p;
  }, {});

  const workOrders = Object.keys(items).filter(
    (item) => ![...otherTimeTypes].includes(item)
  );

  const [editTimeModal, setEditTimeModal] = useState({
    isOpen: false,
    times: [],
    employee: null,
  });

  const onEditTimes = (employee, times, mode, timeLocked) => {
    setEditTimeModal({ timeLocked, mode, times, employee, isOpen: true });
  };

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const otherTimeTypesArray = [...otherTimeTypes];

  return (
    <>
      <Button
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: "100%" }}
        color="primary"
        className="mb-2"
      >
        <div className="d-flex flex-row justify-content-between m-1 align-items-center">
          {employeeName}
          <FontAwesomeIcon
            icon={isOpen ? faChevronDown : faChevronRight}
            fixedWidth
            className="my-1"
          />
        </div>
      </Button>
      <Collapse isOpen={isOpen} className="p-2">
        <ListGroup className="mb-2">
          <ListGroupItem className="bg-lighter text-underline" key={employeeId}>
            Work Orders
          </ListGroupItem>
          {workOrders.length ? (
            workOrders.map((item, index) => (
              <ListGroupItem className="d-flex justify-content-between align-items-center" key={`${employeeId}-${index}`}>
                <div className="d-flex">
                  <span>{workOrderLabels[item]}</span>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} />
                    <span className="ml-1">
                      {items[item]?.startTime
                        ? utils.convertTo12HourFormat(items[item].startTime)
                        : items[item].regHours?.toFixed(2)}
                    </span>
                  </div>
                  <span className="mx-1">/</span>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} color="gray" />
                    <span className="ml-1">
                      {items[item]?.endTime
                        ? utils.convertTo12HourFormat(items[item].endTime)
                        : items[item].otHours?.toFixed(2) || "N/A"}
                    </span>
                  </div>
                  <Button
                    size="sm"
                    className="rounded ml-2"
                    color="info"
                    onClick={() => navigate(`/work-orders/${item}/details`)}
                  >
                    Go to Work Order
                  </Button>
                </div>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem className="text-muted small" key="no-work-order-item">
              No time recorded
            </ListGroupItem>
          )}
          <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter" key="non-job-time">
            <span className="text-underline">Non Job Time</span>
            {otherTimeTypesArray.length ? (
              <Button
                size="sm"
                className="rounded ml-2"
                color="warning"
                onClick={() => {
                  const otherTimes = rawOtherTimes.filter(
                    (ot) => ot.employeeId === employeeId
                  );
                  if (otherTimes.length) {
                    const employee = otherTimes[0].employee;
                    const date = otherTimes[0].date;
                    const serviceLocationId =
                      employee.employeeServiceLocations[0]?.serviceLocationId;
                    const workOrderServiceLocation =
                      authContext.serviceLocations.find(
                        (sl) => sl.id === serviceLocationId
                      );
                    const weekStart = moment(date)
                      .startOf("isoWeek")
                      .format("YYYY-MM-DD");
                    const timeLocked = Boolean(
                      workOrderServiceLocation?.payrollWeekApprovals
                        .map((entry) => entry.weekStart)
                        .find((entry) => entry === weekStart)
                    );
                    onEditTimes(employee, otherTimes, OTHER_TIME, timeLocked);
                  }
                }}
              >
                <FontAwesomeIcon icon={faPen} className="mr-1" />
                <span>Edit</span>
              </Button>
            ) : null}
          </ListGroupItem>
          {otherTimeTypesArray.length ? (
            otherTimeTypesArray.map((item) => (
              <ListGroupItem className="d-flex justify-content-between align-items-center" key={`other-time-types-${employeeId}`}>
                {item}
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} />
                    <span className="ml-1">
                      {items[item].regHours.toFixed(2)}
                    </span>
                  </div>
                  <span className="mx-1">/</span>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} color="gray" />
                    <span className="ml-1">
                      {items[item].otHours.toFixed(2)}
                    </span>
                  </div>
                </div>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem className="text-muted small" key="other-time-types">
              No time recorded
            </ListGroupItem>
          )}
        </ListGroup>
      </Collapse>
      {editTimeModal.isOpen ? (
        <EditEmployeeTime
          timeLocked={editTimeModal.timeLocked}
          mode={editTimeModal.mode}
          times={editTimeModal.times}
          employee={editTimeModal.employee}
          onClose={() => {
            setEditTimeModal({
              isOpen: false,
            });
          }}
          onSubmit={() => {
            setEditTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Update Non-Job Times",
              body: "Non-Job times updated successfully",
              onClose: doRefresh,
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            doRefresh();
          }}
        />
      ) : null}
    </>
  );
};

const TechnicianDailyReport = () => {
  const [authContext] = useAuth();

  const [addOtherTimeModal, setAddOtherTimeModal] = useState();
  const [data, setData] = useState({
    employeeNames: {},
    employeeData: {},
    workOrderLabels: {},
  });
  const [loading, setLoading] = useState();
  const [date, setDate] = useState(moment());
  const [refresh, setRefresh] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    reportsApi
      .technicianDailyReport({
        date: date.format("YYYY-MM-DD"),
        serviceLocationId: authContext.serviceLocationId,
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      });
  }, [date, authContext.serviceLocationId, refresh]);

  return (
    <Container fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Technician Daily Report</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              color="success"
              size="sm"
              className="mr-2 rounded"
              onClick={() => setAddOtherTimeModal(true)}
            >
              <span>Add Time</span>
            </Button>
            <div className="d-flex justify-content-between">
              <Button
                className="my-1"
                onClick={() => setDate(moment(date).add(-1, "day"))}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <div className="m-1 p-2 border rounded min-width-100 font-weight-bold text-center">
                {utils.formatDateAlt(date)}
              </div>
              <Button
                className="my-1"
                onClick={() => setDate(moment(date).add(1, "day"))}
                disabled={date.isSame(moment(), "date")}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader size="sm" />
          ) : Object.keys(data.employeeNames).length ? (
            Object.keys(data.employeeNames).map((employeeId) => (
              <EmployeeEntry
                key={employeeId}
                employeeId={employeeId}
                employeeName={data.employeeNames[employeeId]}
                employeeData={data.employeesData[employeeId]}
                workOrderLabels={data.workOrderLabels}
                doRefresh={() => setRefresh(!refresh)}
                rawOtherTimes={data.rawOtherTimes}
              />
            ))
          ) : (
            <div className="text-center text-muted mt-4">
              No data has been recorded on the selected date
            </div>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : addOtherTimeModal ? (
        <AddOtherTimeModal
          onSubmit={() => {
            setRefresh(!refresh);
            setAddOtherTimeModal(false);
            setInformationModal({
              isOpen: true,
              title: `Add Non-Job Times`,
              body: "Non-Job Time added successfully.",
              onClose: () => {
                setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                });
              },
            });
          }}
          onClose={() => setAddOtherTimeModal(false)}
        />
      ) : null}
    </Container>
  );
};

export default TechnicianDailyReport;
