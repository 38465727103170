import axios from "axios";
import config from "../config/config";
import { utils } from "../utils/utils";

const handleError = (err) => {
  if (err?.response?.status === 401) {
    window.location.href = `/auth/sign-in?returnURL=${window.location.pathname}&reason=1`;
  } else if (err?.response?.status === 404) {
    window.location.href = "/error/404";
  } else if (err?.response?.status === 400 || err?.response?.status === 422) {
    throw err;
  } else {
    window.location.href = "/error/500";
  }
};

export const otherTimeTypesApi = {
  getOtherTimeTypes: (data) => {
    const query = data ? utils.buildQueryString(data) : "";
    return axios(`/api/other-time-types?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => handleError(err));
  },
};
