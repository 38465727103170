import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClock, faLock, faPen } from "@fortawesome/free-solid-svg-icons";

import { ListGroup, ListGroupItem, Badge, Button } from "reactstrap";

import InformationModal from "../../../InformationModal";

import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../../../providers/workOrderDetailsProvider";
import EditEmployeeTime from "../../../../pages/admin/reports/EditEmployeeTime";
import { utils } from "../../../../utils/utils";
import { useAuth } from "../../../../providers/authProvider";

import moment from "moment";
import TooltipItem from "../../../TooltipItem";
import { UncontrolledTooltip } from "reactstrap/lib";

const Technicians = ({ date, trip }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const employeeWorkDays = trip.workOrderEmployees
    .flatMap((workOrderEmployee) =>
      workOrderEmployee.employeeWorkDays.map((item) => ({
        ...item,
        workOrderEmployeeId: workOrderEmployee.id,
      }))
    )
    .filter((ewd) => moment(ewd.date).isSame(moment(date), "date"));

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [editTimeModal, setEditTimeModal] = useState({
    isOpen: false,
    times: [],
    employee: null,
    billableTimesException: [],
  });

  const onEditWorkTime = (
    employee,
    times,
    timeLocked,
    billableTimesException
  ) => {
    setEditTimeModal({
      timeLocked,
      times,
      employee,
      isOpen: true,
      billableTimesException,
    });
  };

  return (
    <>
      <ListGroup className="my-2 col-12 px-0">
        <ListGroupItem className="d-flex justify-content-between align-items-center bg-lighter font-weight-bold">
          Technicians
        </ListGroupItem>
        {trip.workOrderEmployees.map((workOrderEmployee, index) => {
          const tooltipId = `inactiveBadge-${index}`;
          const employeeWorkDay = employeeWorkDays.find(
            (employeeWorkDay) =>
              employeeWorkDay.workOrderEmployeeId === workOrderEmployee.id
          );
          const totalWorkTime = employeeWorkDay?.workTimes.reduce(
            (p, c) => p + c.hours,
            0
          );
          const totalOverTime = employeeWorkDay?.workTimes.reduce(
            (p, c) => p + c.overtimeHours,
            0
          );
          const workOrderServiceLocation = authContext.serviceLocations.find(
            (sl) => sl.id === workOrderDetails.workOrder.serviceLocationId
          );
          const weekStart = moment(employeeWorkDay?.date)
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          const timeLocked = employeeWorkDay
            ? Boolean(
                workOrderServiceLocation?.payrollWeekApprovals
                  .map((entry) => entry.weekStart)
                  .find((entry) => entry === weekStart)
              )
            : false;
          let formattedStartTime;
          let formattedEndTime;
          let totalHours;
          if (workOrderDetails.workOrder.isNewTimeEntry) {
            const startTime = employeeWorkDay?.workTimes[0]?.startTime;
            formattedStartTime = startTime
              ? utils.convertTo12HourFormat(startTime)
              : "N/A";
            const endTime = employeeWorkDay?.workTimes[0]?.endTime;
            formattedEndTime = endTime
              ? utils.convertTo12HourFormat(endTime)
              : "N/A";
            totalHours =
              startTime && endTime
                ? moment(endTime).diff(moment(startTime), "hours", true)
                : 0;
          }
          return (
            <ListGroupItem
              key={workOrderEmployee.id}
              className="d-flex justify-content-between align-items-center"
            >
              <div className="d-flex justify-content-between align-items-center">
                <span>
                  {utils.getEmployeeLabelWithPreferredName(
                    workOrderEmployee.employee
                  )}
                </span>
                {workOrderEmployee?.disabledAt ? (
                  <div key={employeeWorkDay?.id}>
                    <Badge id={tooltipId} className="ml-3" pill color="danger">
                      Inactive
                    </Badge>

                    <UncontrolledTooltip
                      placement="top"
                      target={`inactiveBadge-${index}`}
                    >
                      Disabled At:{" "}
                      {workOrderEmployee?.disabledAt
                        ? utils.formatDate(workOrderEmployee?.disabledAt)
                        : "N/A"}
                      <br />
                      Disabled By:{" "}
                      {workOrderEmployee?.authorDisabled
                        ? utils.getEmployeeLabelWithPreferredName(
                            workOrderEmployee?.authorDisabled
                          )
                        : "N/A"}
                    </UncontrolledTooltip>
                  </div>
                ) : null}
                {employeeWorkDay?.isLeadDay ? (
                  <Badge className="ml-3" pill>
                    Lead
                  </Badge>
                ) : null}
              </div>
              {employeeWorkDay?.workTimes?.length ? (
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-flex align-items-center ml-2">
                    <FontAwesomeIcon icon={faClock} />
                    {workOrderDetails.workOrder.isNewTimeEntry ? (
                      <span className="ml-1">{formattedStartTime}</span>
                    ) : (
                      <span className="ml-1">{totalWorkTime.toFixed(2)}</span>
                    )}
                  </div>
                  <span className="mx-1">/</span>
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faClock} color="gray" />
                    {workOrderDetails.workOrder.isNewTimeEntry ? (
                      <span className="ml-1">{formattedEndTime}</span>
                    ) : (
                      <span className="ml-1">{totalOverTime.toFixed(2)}</span>
                    )}
                  </div>
                  {workOrderDetails.workOrder.isNewTimeEntry &&
                    formattedEndTime !== "N/A" &&
                    totalHours !== 0 && (
                      <Badge className="ml-2">Total Hours: {totalHours}</Badge>
                    )}
                  <Button
                    size="sm"
                    className="rounded ml-2"
                    color="warning"
                    onClick={() =>
                      onEditWorkTime(
                        workOrderEmployee.employee,
                        employeeWorkDay.workTimes,
                        timeLocked,
                        employeeWorkDay.workTimesBillableTimeException
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPen} className="mr-1" />
                    <span>Edit</span>
                  </Button>
                  {timeLocked ? (
                    <TooltipItem
                      position="bottom"
                      id="payroll-tooltip"
                      title="This payroll week is locked"
                    >
                      <FontAwesomeIcon
                        icon={faLock}
                        className="ml-2 text-danger"
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  ) : null}
                </div>
              ) : (
                <small className="text-muted">No work recorded</small>
              )}
            </ListGroupItem>
          );
        })}
      </ListGroup>
      {editTimeModal.isOpen ? (
        <EditEmployeeTime
          timeLocked={editTimeModal.timeLocked}
          times={editTimeModal.times}
          billableTimesException={editTimeModal.billableTimesException}
          employee={editTimeModal.employee}
          isNewTimeEntry={workOrderDetails.workOrder.isNewTimeEntry}
          date={date}
          onClose={() => {
            setEditTimeModal({
              isOpen: false,
            });
          }}
          onSubmit={() => {
            setEditTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Update Work Times",
              body: "Work times updated successfully",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
          onDelete={() => {
            setEditTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Delete Billable Time",
              body: "Billable Time Exception was successfully deleted",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
          onDeleteWorkTime={() => {
            setEditTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Delete Work Time",
              body: "Work Time was successfully deleted",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </>
  );
};

export default Technicians;
