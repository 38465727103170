import React, { useState } from "react";

import {
  faBell,
  faChevronDown,
  faChevronRight,
  faExclamationCircle,
  faPen,
  faToolbox,
  faTools,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Row, Collapse } from "reactstrap";

import Label from "reactstrap/lib/Label";

import InformationModal from "../../InformationModal";
import ContactModal from "../../ContactModal";
import ConfirmationModal from "../../ConfirmationModal";
import DateRangeLabel from "../../DateRangeLabel";
import {
  useWorkOrderDetails,
  ACTIONS as useWorkOrderDetailsActions,
} from "../../../providers/workOrderDetailsProvider";

import { workOrdersApi } from "../../../services/workOrdersServices";
import TooltipItem from "../../TooltipItem";
import {
  ACTIONS,
  MODES,
  useScheduleModal,
} from "../../../providers/scheduleModalProvider";

const WorkOrderTrip = ({ trip }) => {
  const [, setScheduleModalContext] = useScheduleModal();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [isOpen, setIsOpen] = useState(true);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [showContactModal, setShowContactModal] = useState({
    isOpen: false,
    title: "",
    isEmail: false,
    defaultRecipients: [],
  });

  const sendNotification = (data) => {
    setShowContactModal({
      isOpen: false,
    });
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await workOrdersApi.sendNotificationEmail({
            recipients: trip.workOrderEmployees.map(
              (woe) => woe.employee.email
            ),
            subject: data.subject,
            body: data.content,
          });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Send Notification by Email",
            body: "Notification sent successfully",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setShowContactModal({
                isOpen: false,
              });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setShowContactModal({
            isOpen: false,
          });
          setInformationModal({
            isOpen: true,
            title: "Send Notification by Email",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setShowContactModal({
          isOpen: false,
        });
      },
      title: "Send Notification by Email",
      body: `<span class="text-center">Do you confirm you want to send this notification?</span>`,
      confirmColor: "danger",
    });
  };

  const sendNotificationApp = async () => {
    const workOrderId = workOrderDetails.workOrder.id;
    const dataNotification = {
      workOrderId,
    };
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await workOrdersApi.sendNotificationApp(dataNotification);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Send Push Notification",
            body: "Notification sent successfully",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Send Push Notification",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Send Push Notification",
      body: `<span class="text-center">Do you confirm you want to send this notification?</span>`,
      confirmColor: "danger",
    });
  };

  const onEditSchedule = () =>
    setScheduleModalContext({
      action: ACTIONS.INIT,
      payload: {
        currentData: trip,
        mode: MODES.MODE_WORK_ORDER_TRIP,
      },
    });

  const onDeleteTrip = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await workOrdersApi.deleteWorkOrderTrip({
            id: trip.id,
          });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Delete Work Order Trip",
            body: "Work Order Trip Deleted Successdully",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setWorkOrderDetails({
                action: useWorkOrderDetailsActions.REFRESH,
              });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Delete Work Order Trip",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Delete Work Order Trip",
      body: `<span class="text-center">Do you confirm you want to delete this trip?</span>`,
      confirmColor: "danger",
    });
  };

  return (
    <div className="d-flex flex-column my-3">
      <Button
        className="d-flex align-items-center justify-content-between"
        color="primary"
        key={trip.id}
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: "100%" }}
      >
        <div className="d-flex justify-content-between m-1 align-middle">
          <span>Trip {trip.tripNumber}</span>
        </div>
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={isOpen ? faChevronDown : faChevronRight}
            fixedWidth
            className="my-1 ml-2"
          />
        </div>
      </Button>
      <Collapse
        isOpen={isOpen}
        className="p-2 border-left border-right border-bottom rounded-bottom bg-lighter"
      >
        <div className=" d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-nowrap mt-3 mb-4 align-items-center">
              <Label className="col-2 text-underline mb-0">Trip Dates</Label>
              <Row>
                <DateRangeLabel
                  background="#F6FAFF"
                  startDate={trip.startDate}
                  endDate={trip.endDate}
                />
              </Row>
            </div>
            <div className="d-flex align-items-start justify-content-between">
              <div className="d-flex flex-nowrap mb-2 w-100">
                <Label className="col-2 text-underline mb-0">
                  Assigned Techs
                </Label>
                <Row className="flex-grow-1 d-flex justify-content-between align-items-start">
                  <div className="d-flex flex-wrap">
                    {trip.workOrderEmployees
                      .filter(
                        (workOrderEmployee) => !workOrderEmployee?.disabledAt
                      )
                      .sort((workOrderEmployee) =>
                        workOrderEmployee.isLead ? -1 : 1
                      )
                      .map((workOrderEmployee) => {
                        return (
                          <div
                            style={{ background: "#F6FAFF" }}
                            key={workOrderEmployee.id}
                            className="flex-wrap mx-2 mb-2 d-flex align-items-center py-1 px-2 border rounded"
                          >
                            <FontAwesomeIcon
                              icon={
                                workOrderEmployee.isLead ? faToolbox : faTools
                              }
                              className={`mr-2 text-${
                                workOrderEmployee.isLead
                                  ? "primary"
                                  : "secondary"
                              }`}
                              style={{ paddingBottom: "1px" }}
                            />
                            <div className="d-flex align-items-center">
                              <span
                                className={`${
                                  !workOrderEmployee.employee.isActive
                                    ? "text-muted"
                                    : ""
                                }`}
                              >
                                {`${workOrderEmployee.employee.firstName} ${
                                  workOrderEmployee.employee.lastName
                                }${
                                  !workOrderEmployee.employee.isActive
                                    ? " (Inactive)"
                                    : ""
                                }`}
                              </span>
                              {!workOrderEmployee.employee.serviceLocations?.find(
                                (sl) =>
                                  sl.id ===
                                  workOrderDetails.workOrder.serviceLocationId
                              ) ? (
                                <TooltipItem
                                  id={workOrderEmployee.id}
                                  title={"Different Service Location"}
                                >
                                  <FontAwesomeIcon
                                    size="sm"
                                    icon={faExclamationCircle}
                                    className={`ml-2 text-warning`}
                                    style={{ paddingBottom: "1px" }}
                                  />
                                </TooltipItem>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Row>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            {trip.workOrderEmployees?.length ? (
              <Button
                size="sm"
                color="tertiary"
                className="rounded ml-2 mb-2"
                onClick={() =>
                  setShowContactModal({
                    isOpen: true,
                    title: "Send Notification by Email",
                    isEmail: true,
                    defaultRecipients: [
                      trip.workOrderEmployees.map((woe) => woe.employee.email),
                    ],
                  })
                }
              >
                <FontAwesomeIcon icon={faBell} className="mr-2" />
                <span>Email Notification</span>
              </Button>
            ) : null}
            {trip.workOrderEmployees?.length ? (
              <Button
                size="sm"
                color="info"
                className="rounded ml-2 mb-2"
                onClick={() => sendNotificationApp()}
              >
                <FontAwesomeIcon icon={faBell} className="mr-2" />
                <span>Push Notification</span>
              </Button>
            ) : null}
            <Button
              size="sm"
              className="rounded ml-2 mb-2"
              color="warning"
              onClick={onEditSchedule}
            >
              <FontAwesomeIcon icon={faPen} className="mr-2" />
              <span>Edit</span>
            </Button>
            <Button
              size="sm"
              className="rounded ml-2 mb-2"
              color="danger"
              onClick={onDeleteTrip}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
              <span>Delete</span>
            </Button>
          </div>
        </div>
      </Collapse>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : showContactModal.isOpen ? (
        <ContactModal
          {...showContactModal}
          isTripNotification={true}
          doSubmit={sendNotification}
          recipientsEditable={false}
          onClose={() =>
            setShowContactModal({
              isOpen: false,
            })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default WorkOrderTrip;
