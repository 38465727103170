import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CustomInput, Input } from "reactstrap";
import ModalEdit from "../../Edition/ModalEdit";
import { useState } from "react";
import Loader from "../../../../../Loader";
import InformationModal from "../../../../../InformationModal";
import { cranesInspectionAttachmentApi } from "../../../../../../services/craneInspectionAttachmentServices";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../../../../providers/workOrderDetailsProvider";

const EditAnswerModal = ({
  craneInspectionPointOptions,
  selectedOption,
  answer,
  isNumber = null,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(selectedOption);
  const [measurementAnswer, setMeasurementAnswer] = useState(answer);
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });
  const [, setWorkOrderDetails] = useWorkOrderDetails();

  const onSubmit = async () => {
    setLoading(true);
    try {
      await cranesInspectionAttachmentApi.updateCraneInspectionAnswer({
        ...answer,
        craneInspectionPointOptionId: selectedAnswer,
        ...(isNumber && { value: measurementAnswer?.value || "" }),
      });
      setOpenModal(false);
      setInformationModal({
        isOpen: true,
        title: "Answer update",
        body: `The answer was successfully updated`,
        onClose: () => {
          setLoading(false);
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setLoading(false);
      setOpenModal(false);
      setInformationModal({
        isOpen: true,
        title: `Answer update`,
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const selectedAnswerOption = craneInspectionPointOptions?.find(
    (option) => option.id === selectedAnswer
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
      }
    />
  ) : (
    <>
      <Button
        size="sm"
        className="py-0 px-1 rounded ml-2"
        color="warning"
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={faPen} />
      </Button>
      <ModalEdit
        isOpen={openModal}
        onToggle={() => setOpenModal(false)}
        title="Edit Answer"
        onCancel={() => setOpenModal(false)}
        onSave={onSubmit}
        isSaveDisabled={
          loading ||
          (!isNumber &&
            (!selectedAnswerOption ||
              craneInspectionPointOptions?.length === 0))
        }
      >
        {loading ? (
          <Loader size="sm" />
        ) : isNumber ? (
          <Input
            id="measurementResponse"
            type="text"
            name="measurementResponse"
            value={measurementAnswer?.value || ""}
            onChange={(e) =>
              setMeasurementAnswer({ ...answer, value: e.target.value })
            }
          />
        ) : craneInspectionPointOptions?.length ? (
          <CustomInput
            id="availableOptions"
            type="select"
            name="availableOptions"
            value={selectedAnswer || ""}
            onChange={(e) => {
              setSelectedAnswer(parseInt(e.target.value));
            }}
          >
            {!selectedAnswerOption ? (
              <option value="">Select an option...</option>
            ) : null}
            {craneInspectionPointOptions?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </CustomInput>
        ) : (
          "There are no options to select"
        )}
      </ModalEdit>
    </>
  );
};

export default EditAnswerModal;
